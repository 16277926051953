import React, { useEffect, useState } from "react";
import Breadcrumb from './Breadcrumb';
import Productslider from './Productslider';
import axios from 'axios';
import { useParams } from "react-router";
import { Link } from 'react-router-dom';

function Addproductcontent() {

    const params = useParams();
    const id= params.id.toString();

    const [categoryDropdown, setcategoryDropdown] = useState([]);
    const [maincategoryDropdown, setMaincategoryDropdown] = useState([]);
    const [typeDropdown, settypeDropdown] = useState([]);
    const [inputs, setInputs] = useState([]);
    const [price, setPrice] = useState([]);
    const [file, setFile] = useState(null);
    const [counter, setCounter] = useState(1);
    const [addonData, setaddonData] = useState([]);
    const [prodName, setProdName] = useState('');
    //const [available, setAvailable] = useState(["Yes","No"]);


     const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        type_id: 1,
        current_price: '',
        product_status:'',
        description:'',
        image:'',
        addon:'',
        available:'',
        main_category_id:'',
        keywords:''

       });
    
    useEffect(() => {
       mainCatDropdown();
       loadData();
       TypeDropdown();
       
    }, []);

    const loadData=()=>{
        fetch("https://api.novetheitaliankitchen.in/index.php/Api/Productdata", {
            method: "POST",
            body: JSON.stringify({
                pid: id,
            }),
            headers: {
            Accept: "application/json",
            "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => res.json())
            .then((result) => {
            //console.log(result);

                if (result.data!=="") {
                    //setProdName(result.data.prodInfo.name)
                    setFormData(result.data.prodInfo);
                    //console.log(result);
                    loadsubcat(result.data.prodInfo);
                    setaddonData(result.data.addonData);
                    const updatedData = [...inputs];
                    const updatedDataPrice = [...price];
                    result.data.addonData.forEach((inputs, index) => {
                        updatedData[index] = inputs.add_on_name;
                        updatedDataPrice[index] = inputs.price;
                    });            
                    setInputs(updatedData);
                    setPrice(updatedDataPrice);
                }
            })
        .catch((err) => {
            //this.setState({ error: 'Invalid username or password' });
            console.log(err.message);

        });
    
}

    const loadsubcat=(data)=>{

        fetch("https://api.novetheitaliankitchen.in/index.php/Api/subcategory", {
            method: "POST",
            body: JSON.stringify({
                main_category_id: data.main_category_id,
            }),
            headers: {
            Accept: "application/json",
            "Content-type": "application/json; charset=UTF-8",
            },
        })
            .then((res) => res.json())
            .then((result) => {
            //console.log(result);
            if (result.data != "") {
                setcategoryDropdown(result.data);  
                setFormData(prevFormData => ({
                    ...prevFormData,
                    category_id: data.category_id
                  }));

            }
            })
        .catch((err) => {
            setcategoryDropdown([]);
            //console.log(err.message);
        });
    }

    const mainCatDropdown=()=>{
        fetch("https://api.novetheitaliankitchen.in/index.php/Api/maincategories", {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              //"Content-type": "application/json; charset=UTF-8",
            },
          })
            .then((res) => res.json())
            .then((result) => {
              //console.log(result);
              if (result.data != "") {
                const data = result.data;
                setMaincategoryDropdown(data);

    
              }
            })
          .catch((err) => {
            console.log(err.message);
          });
}


  const TypeDropdown=()=>{
    fetch("https://api.novetheitaliankitchen.in/index.php/Api/producttype", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            const data = result.data;
            settypeDropdown(data);

          }
        })
      .catch((err) => {
        console.log(err.message);
      });
  }

  const handleAddmore=()=>{
    setCounter(counter + 1);
    console.log(counter);
  }

const removeAddonkey=(key)=>{
    setCounter(counter - 1);
}

const removeAddonName = (idToRemove,name,price) => {

    setInputs((addon) =>
    addon.filter((item) => !name.includes(item))
    );
    setPrice((prices) =>
    prices.filter((item) => !price.includes(item))
  );
  setaddonData((oldValues) => oldValues.filter((item) => item.id !== idToRemove));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    //console.log(name+"/"+value)
  };

  const handleInputChangeloop = (index, value) => {
    const updatedData = [...inputs];
    updatedData[index] = value;
    setInputs(updatedData);
  };

  const handleInputChangeprice = (index, value) => {
    const updatedData = [...price];
    updatedData[index] = value;
    setPrice(updatedData);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };
 
const handlemaincatChange=(event)=>{

    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    fetch("https://api.novetheitaliankitchen.in/index.php/Api/subcategory", {
        method: "POST",
        body: JSON.stringify({
            main_category_id: event.target.value,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((result) => {
          //console.log(result);
          if (result.data != "") {
            setcategoryDropdown(result.data);
          }
        })
      .catch((err) => {
        setcategoryDropdown([]);
        //console.log(err.message);
      });
}

  const handleSubmit = async(event) => {
    event.preventDefault();
 
    // Now you can use formData as needed, e.g., send it to a server, update state, etc.
    //console.log(formData.addon);
  //console.log('Submitted form data:', formData);
    // const updatedData = formData;
    // updatedData.addon = inputs;
    // setFormData(updatedData);
    // console.log('Submitted form data123:', formData);
    // Set the state with the updated array
    // console.log('Submitted :', inputs);
    // console.log('Submitted :', price);

    try {
        const formDataObj = new FormData();
        if(file!=""){
        formDataObj.append('file', file);
        }
        formDataObj.append('name', formData.name);
        formDataObj.append('category_id', formData.category_id);
        formDataObj.append('type_id', formData.type_id);
        formDataObj.append('description', formData.description);
        formDataObj.append('current_price', formData.current_price);
        formDataObj.append('product_status', formData.product_status);
        formDataObj.append('available', formData.available);
        formDataObj.append('main_category_id', formData.main_category_id);
        formDataObj.append('keywords', formData.keywords);
        inputs.forEach((inputs, index) => {
            formDataObj.append(`add_on_name[${index}]`, inputs);
        });
    
        price.forEach((price, index) => {
            formDataObj.append(`add_on_price[${index}]`, price);
        });
        formDataObj.append('id', id);

        
        const response = await axios.post('https://api.novetheitaliankitchen.in/index.php/Api/editproduct', formDataObj, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        //alert('Data updated successfully');
        window.location.href = `/menu-grid?sub_cat_id=${id}`;
      } catch (error) {
        console.error('Error uploading file:', error);
      }


  };

        return (
            <div className="ms-content-wrapper">
                <div className="row">
                    <div className="col-md-12" >
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb pl-0">
                            <li className="breadcrumb-item"><Link to="/"><i className="material-icons">home</i> Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Edit Product</li>
                        </ol>
                    </nav>
                        
                    </div>
                    <div className="col-xl-12 col-md-12">
                        <div className="ms-panel ms-panel-fh">
                            <div className="ms-panel-header">
                                <h6>Edit Product</h6>
                            </div>
                            <div className="ms-panel-body">
                                <form   onSubmit={handleSubmit} >
                                    <div className="form-row">
                                        <div className="col-md-12 mb-3">
                                        <label htmlFor="name">Title</label>
                                        <input type="text" name='name' value={formData.name} onChange={handleInputChange}
                                             className="form-control" id="name" required/>
                                        </div>
                                        {/* <div className="col-md-6 mb-3">
                                            <label htmlFor="main_category_id">Select Catagory</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="main_category_id"  name="main_category_id"   onChange={handlemaincatChange}>
                                                <option value="">--Select--</option>
                                                {maincategoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} selected={item.id === formData.main_category_id}>{item.category_name}</option>
                                                 ))}
                                                    
                                                </select>
                                              
                                            </div>
                                        </div> */}
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="category_id">Select Catagory</label>
                                            <div className="input-group">
                                                <select className="form-control" required id="category_id"  name="category_id"  onChange={handleInputChange}>
                                                <option value="">--Select--</option>
                                                {categoryDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} selected={item.id === formData.category_id}>{item.name}</option>
                                                 ))}
                                                    
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="type_id">Type</label>
                                            <div className="input-group">
                                                <select className="form-control" id="type_id" required name="type_id"  onChange={handleInputChange}>
                                                {typeDropdown.map((item, i) => ( 
                                                    <option value={item.id} key={i} selected={item.id === formData.type_id}>{item.type_name}</option>
                                                 ))}
                                                </select>
                                                
                                            </div>
                                        </div>
                                      
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="current_price">Price</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" id="current_price" placeholder="10" required name="current_price" value={formData.current_price} onChange={handleInputChange}/>
                                                <div className="invalid-feedback">
                                                    Price
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label htmlFor="product_status">Status</label>
                                            <div className="input-group">
                                                <select className="form-control" id="product_status"  name="product_status"  onChange={handleInputChange}>
                                                <option value="">--Select--</option>
                                                <option value="Must Try!" selected={"Must Try!" === formData.product_status}>Must Try</option>
                                                <option value="NEW" selected={"NEW" === formData.product_status}>New</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="available">Available</label>
                                            <div className="input-group">
                                            <select className="form-control" required name="available"  onChange={handleInputChange}>
                                              <option value="Yes" selected={"Yes" === formData.available}> Yes</option>
                                              <option value="No" selected={"No" === formData.available}>No</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="validationCustom12">Product Image</label>
                                            <div className="custom-file productimage">
                                                <input type="file"  name="image" id="validatedCustomFile" onChange={handleFileChange}/>
                                                {/* <label className="custom-file-label" htmlFor="validatedCustomFile">Upload Images...</label> */}
                                                {/* <div className="invalid-feedback">Example invalid custom file feedback</div> */}
                                            </div>
                                        </div>

                                        <div className="col-md-12 ">
                                            <label htmlFor="description">Description</label>
                                            <div className="input-group">
                                                <textarea rows={5} id="description" className="form-control" placeholder="Message"  name="description" value={formData.description} onChange={handleInputChange}/>
                                                <div className="invalid-feedback">
                                                    Please provide a message.
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div className="col-md-12 mb-3">
                                            <label htmlFor="keywords">Keywords</label>
                                            <div className="input-group">
                                                <textarea rows={3} id="keywords" className="form-control" placeholder="Message"  name="keywords" value={formData.keywords} onChange={handleInputChange}/>
                                               
                                            </div>
                                        </div>

                                        <div className="col-md-12 mb-2">
                                            <div className="d-flex">
                                                <div className="d-inline-block col-md-10"> <h6>Add-ons</h6> </div>
                                                <div className="d-inline-block col-md-2"> <i className="fa fa-plus-circle fs-16" onClick={handleAddmore}/> </div>
                                            </div>
                                        </div>

                                      
                                {addonData.map((item, i) => (
                                <div className="col-md-12" key={item.id}>
                                    <div className="row">
                                    <div className="col-md-5 mb-3">
                                        <label>Name</label>
                                        <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.add_on_name || ''}
                                            placeholder="Pizza"
                                            name="add_on_name[]"
                                            onChange={(e) => handleInputChangeloop(i, e.target.value)}
                                        />
                                        <div className="invalid-feedback">Name</div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 mb-3">
                                    <div className="row">
                                      <div className="col-md-10 mb-3">
                                        <label htmlFor="validationCustom25">Price</label>
                                        <div className="input-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            defaultValue={item.price || ''}
                                            name="price[]"
                                            placeholder="10"
                                            onChange={(e) => handleInputChangeprice(i, e.target.value)}
                                        />
                                        <div className="invalid-feedback">Price</div>
                                        </div>
                                        </div>
                                        <div className="col-md-2 mt-4"><i className="fa fa-minus-circle fs-16" onClick={() => removeAddonName(item.id,item.add_on_name,item.price)}/></div>
                                        </div>
                                    </div>
                                    
                                    </div>
                                </div>
                                ))}


                                                                           
                                {Array.from(Array(counter)).map((c, index) => {
                                    const i = index + addonData.length+1;
                                return (
                                    <>
                      
                                   <div className="col-md-12" key={i} >  
                                  <div className="row" >
                                    <div className="col-md-5 mb-3" >
                                            <label htmlFor="validationCustom24">Name </label>
                                            <div className="input-group" >
                                                <input type="text" className="form-control"   placeholder="Pizza"  name="add_on_name" onChange={(e) => handleInputChangeloop(i, e.target.value)}/>
                                                <div className="invalid-feedback">
                                                Name
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 mb-3" >
                                          <div className="row">
                                           <div className="col-md-10 mb-3">
                                                <label htmlFor="validationCustom25">Price</label>
                                                <div className="input-group">
                                                    <input type="text" className="form-control" name="price"  placeholder="10"  onChange={(e) => handleInputChangeprice(i, e.target.value)}/>
                                                    <div className="invalid-feedback">
                                                        Price
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 mt-4"><i className="fa fa-minus-circle fs-16" onClick={() => removeAddonkey(i)}/></div>
                                           </div>

                                        </div>
                                      </div>
                                    </div>
                          
                                        </>
                                    
                                    );
                                })}
                                        
                                        <div className="ms-panel-header new">
                                          <button className="btn btn-primary d-block" type="submit" >Save</button>
                                        </div>
                                     
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-xl-6 col-md-12 hidden" >
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ms-panel">
                                    <div className="ms-panel-header">
                                        <h6>Product </h6>
                                    </div>
                                    <div className="ms-panel-body">
                                        <Productslider/>
                                        
                                    </div>
                                    <div className="ms-panel-header new">
                                        <p className="medium">Status Available</p>
                                        <div>
                                            <label className="ms-switch">
                                                <input type="checkbox" />
                                                <span className="ms-switch-slider round" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <p className="medium">Discount Active</p>
                                        <div>
                                            <label className="ms-switch">
                                                <input type="checkbox" defaultChecked />
                                                <span className="ms-switch-slider round" />
                                            </label>
                                        </div>
                                    </div>
                                    <div className="ms-panel-header new">
                                        <button className="btn btn-secondary d-block" type="submit">Save</button>
                                        <button className="btn btn-primary d-block" type="submit">Save and Add</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

        );

}

export default Addproductcontent;